"use client";

import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import BaseRoomCarousel from "@/common/partials/RoomCarousel/BaseRoomCarousel";
import { FavoriteRoom } from "~/db/models/FavoriteRoom";
import { useSearchForCarouselQuery } from "~/generated/v2/graphql";
import { GraphqlVersion } from "~/lib/apolloClientLinkStrategy";
import { WheresBuilder } from "~/queries/builders/search/wheresBuilder";
import { nonNullable } from "~/utils/typeUtil";

type Props = {
  title: JSX.Element;
  excludeRoomId?: number;
};

/**
 * お気に入り物件のカルーセルコンポーネント
 */
const FavoriteRoomCarousel = (props: Props): JSX.Element => {
  const router = useRouter();
  const [favoriteRooms, setFavoriteRooms] = useState<FavoriteRoom[]>([]);

  const getFavoriteRooms = async () => {
    const favoriteRooms = await FavoriteRoom.getAllExcept(
      [props.excludeRoomId].filter(nonNullable),
    );
    setFavoriteRooms(favoriteRooms);
  };

  const extractRoomIds = (favoriteRooms: FavoriteRoom[]) => {
    return favoriteRooms.map((r) => r.roomId);
  };

  const { loading, error, data } = useSearchForCarouselQuery({
    fetchPolicy: "no-cache",
    variables: {
      where: WheresBuilder.byIds(extractRoomIds(favoriteRooms)),
    },
    context: { api: GraphqlVersion.V2 },
  });

  useEffect(() => {
    getFavoriteRooms();
  }, [router]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading || error || !data || data.search.items.length === 0) {
    return <></>;
  }

  return <BaseRoomCarousel items={data.search.items} title={props.title} />;
};

export default FavoriteRoomCarousel;
