"use client";

import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import BaseRoomCarousel from "@/common/partials/RoomCarousel/BaseRoomCarousel";
import { ViewHistory } from "~/db/models/ViewHistory";
import { useSearchForCarouselQuery } from "~/generated/v2/graphql";
import { GraphqlVersion } from "~/lib/apolloClientLinkStrategy";
import { WheresBuilder } from "~/queries/builders/search/wheresBuilder";
import { nonNullable } from "~/utils/typeUtil";

type Props = {
  title?: JSX.Element;
  excludeRoomId?: number;
};

/**
 * 閲覧履歴のカルーセルコンポーネント
 */
const ViewHistoryCarousel = (props: Props): JSX.Element => {
  const router = useRouter();

  const [viewHistories, setViewHistories] = useState<ViewHistory[]>([]);

  /**
   * 閲覧履歴を取得する
   */
  const getViewHistories = async () => {
    // NOTE: 成約済みの物件が含まれている可能性があるため50件分取得している
    setViewHistories(await ViewHistory.getLatest(50, [props.excludeRoomId].filter(nonNullable)));
  };

  /**
   * 閲覧履歴から部屋IDを取得する
   */
  const extractRoomIds = (viewHistories: ViewHistory[]) => {
    return viewHistories.map((h) => h.roomId);
  };

  /**
   * 物件データを作成する（閲覧日時の降順）
   */
  const { loading, error, data } = useSearchForCarouselQuery({
    fetchPolicy: "no-cache",
    variables: {
      where: WheresBuilder.byIds(extractRoomIds(viewHistories)),
    },
    context: { api: GraphqlVersion.V2 },
  });

  useEffect(() => {
    getViewHistories();
  }, [router]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading || error || !data || data.search.items.length === 0) {
    return <></>;
  }

  return <BaseRoomCarousel items={data.search.items} title={props.title} />;
};

export default ViewHistoryCarousel;
