import Image from "next/image";
import Link from "next/link";
import { FC } from "react";
import styles from "@/pages/top/AppBanner/AppBanner.module.scss";
import appStore from "public/static/images/app/app-store.png";
import phone from "public/static/images/app/double-phone-pc.png";
import googlePlay from "public/static/images/app/google-play.png";
import appIcon from "public/static/images/icon/airdoor-app-icon.png";
import { APP_STORE_LINK, GOOGLE_PLAY_LINK } from "~/const/app";

const AppBanner: FC = () => (
  <div className={styles.container}>
    <div className={styles.isFlex}>
      <Image
        src={phone}
        className={styles.phone}
        width={227}
        height={190}
        alt="２つのスマートフォン"
      />
      <div className={styles.catchContainer}>
        <p className={styles.yellowText}>公式アプリリリース！</p>
        <p>
          <span className={styles.largeText}>プッシュ通知</span>
          <span className={styles.cross}>×</span>
          <span className={styles.largeText}>一次情報</span>
          <span className={styles.smallText}>で</span>
        </p>
        <p className={styles.largeText}>人気の物件を先取りしよう</p>
      </div>
    </div>
    <div className={styles.line} />
    <div className={styles.isFlex}>
      <div className={styles.appIcon}>
        <Image src={appIcon} fill alt="エアドアアプリアイコン" />
      </div>
      <div className={styles.qr}>
        <Image src="/static/images/app/qr.svg" width={78} height={78} alt="qrコード" />
      </div>
      <div className={styles.appLinks}>
        <Link href={APP_STORE_LINK} className={styles.appStore} target="_blank">
          <Image src={appStore} fill alt="apple store リンク" />
        </Link>
        <Link href={GOOGLE_PLAY_LINK} className={styles.googlePlay} target="_blank">
          <Image src={googlePlay} fill alt="google play リンク" />
        </Link>
      </div>
    </div>
  </div>
);

export default AppBanner;
