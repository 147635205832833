import React from "react";
import styles from "../CommonButton.module.scss";

type ActionButtonProps = {
  text: string;
  onClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | null;
  className?: string;
  svg?: JSX.Element | null;
  type?: "outlined" | "filled";
  color?: "cv" | "cv2" | "transparent" | "blue";
  size?: "default" | "thin";
  decoration?: "arrow";
  shape?: "roundedRectangle";
  is3d?: boolean;
};

export const ActionButton = ({
  text,
  onClick,
  className,
  svg,
  type,
  color,
  size,
  decoration,
  shape,
  is3d,
}: ActionButtonProps): JSX.Element => {
  const getClassName = (option: string | undefined, defaultOption?: string) => {
    if (option !== undefined && option in styles) {
      return styles[option];
    }
    return defaultOption ? styles[defaultOption] : "";
  };

  // 描画の制御
  const typeClass = getClassName(type, "filled");
  // 色の制御
  const colorClass = getClassName(color, "blue");
  // サイズの制御
  const sizeClass = getClassName(size, "default");
  // 装飾の制御
  const decoClass = getClassName(decoration);
  // 形状の制御
  const shapeClass = getClassName(shape);
  // 3Dスタイルの制御
  const button3dClass = is3d ? styles.is3d : "";
  // 付与するクラス名
  const cls = `${styles.body} ${typeClass} ${colorClass} ${sizeClass} ${decoClass} ${shapeClass} ${className} ${button3dClass}`;

  return (
    <button className={cls} onClick={onClick}>
      {svg}
      <span>{text}</span>
    </button>
  );
};
