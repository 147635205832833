"use client";

import { useRouter } from "next/navigation";
import { ActionButton } from "@/common/buttons/ActionButton/ActionButton";
import WomanImg from "/public/static/svgs/common/blue_woman.svg";
import styles from "@/common/partials/NoListedInquiry/NoListedInquiry.module.scss";

const MainContent = () => {
  const { push } = useRouter();
  return (
    <div>
      <div className={styles.header}>
        <div className={styles.imageWrap}>
          <WomanImg />
        </div>
        <p className={styles.headerText}>＼気になる物件がない...という方も／</p>
      </div>

      <div className={styles.mainContent}>
        <p className={styles.catch}>
          エアドア掲載以外の
          <br className="is-sp-only" />
          物件について相談する
        </p>
        <div className={styles.buttonsWrap}>
          <ActionButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              push("/inquiry/no-listed-request");
            }}
            text="物件の相談・見学をしたい【無料】"
            type="filled"
            color="cv2"
            size="thin"
            shape="roundedRectangle"
            is3d
            className={styles.actionButton}
          ></ActionButton>
          {/* <ActionButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              push("/inquiry/consultation-request");
            }}
            text="来店orオンラインで提案を受けたい"
            type="filled"
            color="cv"
            size="thin"
            shape="roundedRectangle"
            is3d
            className={styles.actionButton}
          ></ActionButton> */}
        </div>
      </div>
    </div>
  );
};

export default MainContent;
